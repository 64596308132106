import React, { Suspense } from "react";
import { lazy } from "react";
import thumbnail from "../src/images/building-images/building-img.jpeg";
import mail from "../src/images/mail.svg";
import call from "../src/images/call.svg";
import add from "../src/images/add.svg";
import { Link } from "react-router-dom";
import buildingImg1 from "./images/building-images/building-img-1.jpeg";
import buildingImg5 from "./images/building-images/building-img-5.jpeg";
import dinning1 from "./images/building-images/dinning1.jpeg";
import dinning2 from "./images/building-images/dinning2.jpeg";
import dinning3 from "./images/building-images/dinning3.jpeg";
import dinning4 from "./images/building-images/dinning4.jpeg";
import dinning5 from "./images/building-images/dinning5.jpeg";
import dinning7 from "./images/building-images/dinning7.jpeg";
import kitchen from "./images/building-images/kitchen.jpeg";
import newImage1 from "./images/building-images/newImage1.jpg";
import newImage2 from "./images/building-images/newImage2.jpg";
import newImage3 from "./images/building-images/newImage3.jpg";
import newImage4 from "./images/building-images/newImage4.jpg";
import newImage5 from "./images/building-images/newImage5.jpg";
import newImage6 from "./images/building-images/newImage6.jpg";
import newImage7 from "./images/building-images/newImage7.jpg";
import newImage8 from "./images/building-images/newImage8.jpg";
import newImage9 from "./images/building-images/newImage9.jpg";
import kontrakt from "url:../Documents/Kontrakt-Fredriksborg.pdf";
import Rutiner from "url:../Documents/Brannverns-Rutiner.pdf";
import docIcon from "../src/images/icons-document.svg";
import Calendar from "../src/Utilities/Calendar.js";

const FullFilm = lazy(() => import("./FullFilm.js"));

const Rent = () => {
  return (
    <div className="Rent-container">
      <div className="Rent-box">
        <div className="img-section">
          <img src={thumbnail} alt="image loading" />
          <div className="opacity-style" style={{ height: "60vh" }}></div>
        </div>
        <div className="image-text">
          Perfekt ramme og atmosfære for ditt selskap.
        </div>
      </div>
      <div className="Rent-content">
        <div className="Rent-text">
          <div
            className="event-content"
            style={{ fontSize: "20px", color: "#000" }}
          >
            I dag fungerer Fredriksborg som menighetshus med en rekke
            aktiviteter for barn og voksne. Hver siste søndag i måneden
            arrangeres det aftengudstjeneste og Onsdagsklubb en har sine møter
            her. I kjelleren drives det en familiebarnehage og annen etasje er
            utleid til hybler.
            <br />
            Fredriksborg er også er veldig populært selskapslokale for bryllup,
            merkedager, minnestunder og andre aktiviteter der man har behov for
            et hyggelig møtelokale
          </div>
        </div>
        <div className="Rent-conten-section1">
          <div className="Rent-price">
            <div className="Rent-heading">
              <h4>Prisdetaljer</h4>
            </div>
            <div className="Rent-pricing">
              <ul>
                <li> Hverdager, Søndager - 5000 kr</li>
                <li>Konfirmasjon, Dåp, Minnestund - 3000 kr</li>
                <li>Fredager - 9000 kr</li>
                <li>Lørdager - 10.000 kr</li>
                <li>Hele helgen - 15.000 kr</li>
              </ul>
            </div>
          </div>
          <div className="Rent-Collab">
            <div className="Rent-Collabs">
              <div className="raleway-style">
                <h6 style={{ color: "#fff", fontSize: "22px" }}>
                  Våre samarbeidspartnere
                </h6>
              </div>
              <div className="Rent-Collabs-ul">
                <ul>
                  <li>
                    <div>
                      <span style={{ fontWeight: "600" }}>Bygdø Kolonial</span>
                      <span>
                        <img src={mail} alt="mail to" /> :
                        <a href="mailto:contact@bygdokolonial.no">
                          contact@bygdokolonial.no
                        </a>
                      </span>
                      <span>
                        webside :
                        <a
                          rel="noreferrer noopener"
                          href="http://Bygdokolonial.no"
                          target="_blank"
                        >
                          bygdokolonial.no
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span style={{ fontWeight: "600" }}> Essens</span>
                      <span>
                        <img src={mail} alt="mail to" />:
                        <a href="mailto:post@delicatessencatering.no">
                          post@delicatessencatering.no
                        </a>
                      </span>
                      <span>
                        webside :
                        <a
                          href="https://delicatessencatering.no/?gclid=Cj0KCQjwt-6LBhDlARIsAIPRQcKG6z4aGfi56PjiqJv72zSi9nt8BC6ku149pdMdDn_ijDfdAa-_rikaAgutEALw_wcB"
                          target="_blank"
                        >
                          delicatessencatering.no
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span style={{ fontWeight: "600" }}>SULT</span>
                      <span>
                        <img src={mail} alt="mail to" />:
                        <a href="mailto:booking@sult.no">booking@sult.no</a>
                      </span>
                      <span>
                        webside :
                        <a
                          rel="noreferrer noopener"
                          href="http://sult.no"
                          target="_blank"
                        >
                          sult.no
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span style={{ fontWeight: "600" }}>Fotograf</span>
                      <span>
                        <img src={mail} alt="mail to" />:
                        <a href="mailto:taksrud@gmail.com ">
                          taksrud@gmail.com{" "}
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="Rent-conten-section2">
          <div className="Rent-book">
            <div className="Rent-heading">
              <h4>Se hva som er ledig</h4>
            </div>
            <div className="Rent-calendar-contact">
              <div className="Rent-calendars">
                <Calendar />
                <div style={{ color: "#00000" }}>
                  <span style={{ fontWeight: "600" }}>Merk</span>
                  <span style={{ fontWeight: "500" }}>
                    : Se kalenderen for tilgjengelighet. Bestillingen bekreftes
                    etter godkjenning fra bookingansvarlig.
                  </span>
                </div>
              </div>
              <div className="Rent-contact">
                <div
                  className="contact-text"
                  style={{ fontSize: "24px", color: "#000" }}
                >
                  Takk for at du valgte våre tjenester! For å foreta en
                  bestilling eller spørre om våre tilbud, ta gjerne kontakt med
                  vår dedikerte bookingansvarlig.
                </div>
                <div className="rent-card-block raleway-font">
                  <div className="rent-contact-card">
                    <div>
                      <span style={{ fontWeight: "700" }}>Marianne Haugen</span>
                      <span>
                        <img
                          style={{ padding: "0 5px 0 0" }}
                          alt="call"
                          src={call}
                        />
                        : <img alt="" src={add} />
                        <Link
                          to="#"
                          style={{ paddingLeft: "0px" }}
                          onClick={(e) => {
                            window.location.href = "tel:+4791357478";
                            e.preventDefault();
                          }}
                        >
                          47 913 57 478
                        </Link>
                      </span>
                      <span>
                        <img
                          style={{ padding: "0 5px 0 0" }}
                          alt="mail"
                          src={mail}
                        />
                        :
                        <Link
                          to="#"
                          onClick={(e) => {
                            window.location.href = "mailto:fborg@online.no";
                            e.preventDefault();
                          }}
                        >
                          fborg@online.no
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="rent-contact-card">
                    <div>
                      <span style={{ fontWeight: "700" }}>
                        Liv Anniken Berg
                      </span>
                      <span>
                        <img
                          style={{ padding: "0 5px 0 0" }}
                          alt="call"
                          src={call}
                        />
                        : <img alt="" src={add} />
                        <Link
                          to="#"
                          style={{ paddingLeft: "0px" }}
                          onClick={(e) => {
                            window.location.href = "tel:+4790176005";
                            e.preventDefault();
                          }}
                        >
                          47 901 76 005
                        </Link>
                      </span>
                      <span>
                        <img
                          style={{ padding: "0 5px 0 0" }}
                          alt="mail"
                          src={mail}
                        />
                        :
                        <Link
                          to="#"
                          onClick={(e) => {
                            window.location.href = "mailto:livab6@icloud.com";
                            e.preventDefault();
                          }}
                        >
                          livab6@icloud.com
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="contact-text flex-row"
          style={{ color: "#000", fontSize: "25px" }}
        >
          <div
            style={{ gap: "20px", display: "flex", flexDirection: "column" }}
          >
            Se vårt omfattende kontrakt- og brannrutinedokument for å sikre
            samsvar og sikkerhet i bygningen vår.
            <div className="flex-row flex-row-center">
              <div>
                <a
                  className="flex-column doc-link"
                  style={{ fontSize: "14px", textDecoration: "none" }}
                  href={kontrakt}
                  target="_blank"
                >
                  <img
                    style={{
                      height: "40px",
                      width: "40px",
                      alignSelf: "center",
                    }}
                    src={docIcon}
                    alt="download"
                  />
                  Kontrakt
                </a>
              </div>
              <div>
                <a
                  className="flex-column doc-link"
                  style={{ fontSize: "14px", textDecoration: " none " }}
                  href={Rutiner}
                  target="_blank"
                >
                  <img
                    style={{
                      height: "40px",
                      width: "40px",
                      alignSelf: "center",
                    }}
                    src={docIcon}
                    alt="download"
                  />
                  Brannverns-Rutiner
                </a>
              </div>
            </div>
          </div>
          Vårt bookingansvarlig er forpliktet til å sikre at opplevelsen din hos
          oss er sømløs og hyggelig. Enten du planlegger en hendelse, et
          arrangement eller en spesiell anledning, er vi her for å hjelpe deg
          hele veien. Ta kontakt i dag og la oss hjelpe deg med å skape
          uforglemmelige minner.
        </div>
        <div className="Rent-book">
          <div className="Rent-heading">
            <h4>Visuell omvisning</h4>
          </div>
          <Suspense fallback={thumbnail}>
            <FullFilm />
          </Suspense>
        </div>
      </div>
      <div className="Rent-content">
        <div className="Photographs">
          <img src={newImage1} alt="Images" />
          <img src={newImage2} alt="Images" />
          <img src={newImage3} alt="Images" />
          <img src={newImage4} alt="Images" />
          <img src={newImage5} alt="Images" />
          <img src={newImage6} alt="Images" />
          <img src={newImage7} alt="Images" />
          <img src={newImage8} alt="Images" />
          <img src={newImage9} alt="Images" />
          <img src={buildingImg1} alt="Images" />
          <img src={buildingImg5} alt="Images" />
          <img src={dinning1} alt="Images" />
          <img src={dinning2} alt="Images" />
          <img src={dinning3} alt="Images" />
          <img src={dinning4} alt="Images" />
          <img src={dinning5} alt="Images" />
          <img src={dinning7} alt="Images" />
          <img src={kitchen} alt="Images" />
        </div>
      </div>
    </div>
  );
};

export default Rent;
